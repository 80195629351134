export const colors = {
  strackerDone: '#16a974',
  strackerLightRed: '#f5ad93',
  strackerYellow: '#eda124',
  strackerGrey: '#809eb2',
  strackerRed: '#eb5b27',
  strackerBckGrey: '#f8f9fe',
  strackerLightGrey: '#e0e8fc',
  strackerLightGreen: '#d0eee3',
  strackerSideBarBlue: '#d4e5ff',
  drkMainColor: '#2f3437',
  drkSecondaryColor: '#373c3f',
  drkBorderColor: '#616669',
  drkSeparatorColor: '#373c3f',
  drkMainSelectionBlue: '#2b8ffa',
  primary: '#074ee8',
  label: '#ffffff',
  strackerBlack: '#023e65',
  strackerLightBlue: '#a8c1f7',
  strackerLightYellow: '#f3cf92',
  blue: '#477fe4',
  green: '#6ae6b4',
  purple: '#a17ae6',
  lightPurple: '#a8abf7',
  error: '#ff4848',
};

export const fontFamilies = {
  openSans: 'Open Sans',
  poppins: "'Poppins', sans-serif",
};

export const fontSizes = {
  xxs: '10px',
  xs: '12px',
  s: '14px',
  m: '16px',
  l: '18px',
  xl: '24px',
  xxl: '32px',
};

export const fontWeights = {
  light: '300',
  regular: '400',
  medium: '500',
  semiBold: '600',
  bold: '700',
  extraBold: '800',
};

export const computedFonts = {
  xxs: {
    'font-family': fontFamilies.openSans,
    'font-size': fontSizes.xxs,
    'font-weight': fontWeights.semiBold,
  },
  xs: {
    'font-family': fontFamilies.openSans,
    'font-size': fontSizes.xs,
    'font-weight': fontWeights.semiBold,
  },
  s: {
    'font-family': fontFamilies.openSans,
    'font-size': fontSizes.s,
    'font-weight': fontWeights.semiBold,
  },
  m: {
    'font-family': fontFamilies.openSans,
    'font-size': fontSizes.m,
    'font-weight': fontWeights.semiBold,
  },
  l: {
    'font-family': fontFamilies.openSans,
    'font-size': fontSizes.l,
    'font-weight': fontWeights.semiBold,
  },
  xl: {
    'font-family': fontFamilies.openSans,
    'font-size': fontSizes.xl,
    'font-weight': fontWeights.semiBold,
  },
  xxl: {
    'font-family': fontFamilies.openSans,
    'font-size': fontSizes.xxl,
    'font-weight': fontWeights.semiBold,
  },
  menuSide: {
    'font-family': fontFamilies.poppins,
    'font-size': fontSizes.m,
    'font-weight': fontWeights.regular,
  },
  search: {
    'font-family': fontFamilies.poppins,
    'font-size': fontSizes.s,
    'font-weight': fontWeights.regular,
  },
  pageTitle: {
    'font-family': fontFamilies.poppins,
    'font-size': fontSizes.xl,
    'font-weight': fontWeights.bold,
  },
  // inftrans same than quotationInfo
  quotationInfo: {
    'font-family': fontFamilies.poppins,
    'font-size': fontSizes.s,
    'font-weight': fontWeights.semiBold,
  },
  bigInfo: {
    'font-family': fontFamilies.poppins,
    'font-size': fontSizes.xs,
    'font-weight': fontWeights.semiBold,
  },
  analytics: {
    'font-family': fontFamilies.poppins,
    'font-size': fontSizes.s,
    'font-weight': fontWeights.bold,
  },
  poppinsL: {
    'font-family': fontFamilies.poppins,
    'font-size': fontSizes.l,
    'font-weight': fontWeights.regular,
  },
  poppinsLMedium: {
    'font-family': fontFamilies.poppins,
    'font-size': fontSizes.l,
    'font-weight': fontWeights.medium,
  },
};

// Hex alpha:
// Opacity %   255 Step        2 digit HEX prefix
// 0%          0.00            00
// 5%          12.75           0c
// 10%         25.50           19
// 15%         38.25           26
// 20%         51.00           33
// 25%         63.75           3f
// 30%         76.50           4c
// 35%         89.25           59
// 40%         102.00          66
// 45%         114.75          72
// 50%         127.50          7f
// 55%         140.25          8c
// 60%         153.00          99
// 65%         165.75          a5
// 70%         178.50          b2
// 75%         191.25          bF
// 80%         204.00          cc
// 85%         216.75          d8
// 90%         229.50          e5
// 95%         242.25          f2
// 100%        255.00          ff
