import axios from 'axios';
import { redirect } from 'react-router-dom';
import FormData from 'form-data';
import qs from 'qs';
import config from '../config/config';

const instance = axios.create({
  baseURL: config.apiUrl,
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
  // withXSRFToken: true,
});

const onError = error => {
  if (config.debug) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
  if (error.response.status === 401) {
    redirect('/login');
    return null;
  }
  throw error;
};

const requestWrapper = async (method, route, body) => {
  try {
    const { status, data } = await method(
      route,
      body != null ? body : undefined,
    );
    if (status === 204) return true;
    return data;
  } catch (error) {
    return onError(error);
  }
};

/**
 * AUTH
 */

const login = (email, password) => {
  return requestWrapper(instance.post, '/authentication/login', {
    email,
    password,
  });
};

const logout = () => {
  return requestWrapper(instance.delete, '/authentication/logout');
};

/**
 * USERS
 */

const confirmEmail = token => {
  return requestWrapper(instance.patch, '/users/email/confirm', {
    token,
  });
};

const confirmPhoneNumber = code => {
  return requestWrapper(instance.patch, '/users/current/phone/confirm', {
    code,
  });
};

const resendPhoneConfirmationCode = () => {
  return requestWrapper(instance.post, '/users/current/phone/code/resend');
};

const requestResetPassword = email => {
  return requestWrapper(instance.post, '/users/password/reset', {
    email,
  });
};

const updatePassword = (token, password) => {
  return requestWrapper(instance.patch, '/users/password/reset', {
    token,
    password,
  });
};

const getCurrentUser = () => {
  return requestWrapper(instance.get, '/users/current');
};

const getUserById = id => {
  return requestWrapper(instance.get, `/users/id/${id}`);
};

const updateUser = (id, values) => {
  return requestWrapper(instance.patch, `/users/${id}`, values);
};

const updateUserRole = (companyId, id, values) => {
  const query = companyId != null ? `?companyId=${companyId}` : '';
  return requestWrapper(instance.patch, `/users/${id}/role${query}`, values);
};

const uploadUserPicture = async file => {
  const formData = new FormData();
  try {
    formData.append('file', file);
    return instance.patch('/users/current/picture', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  } catch (error) {
    return onError(error);
  }
};

const deactivateUser = id => {
  return requestWrapper(instance.patch, `/users/${id}/deactivate`);
};

const getCurrentUserPreferences = companyId => {
  return requestWrapper(
    instance.get,
    `/users/current/companies/${companyId}/preferences`,
  );
};

const updateCurrentUserPreferences = (companyId, values) => {
  return requestWrapper(
    instance.patch,
    `/users/current/companies/${companyId}/preferences`,
    values,
  );
};

/**
 * COMPANIES
 */

const getCompanies = (pageSize = 20, pageNumber = 0, filters = null) => {
  return requestWrapper(
    instance.get,
    `/companies?page_size=${pageSize}&page_number=${pageNumber}` +
      `${filters != null ? `&${qs.stringify({ filters })}` : ''}`,
  );
};

const getCompanyUsers = async id => {
  return (await requestWrapper(instance.get, `/companies/${id}/users`)).items;
};

const updateCompany = (id, values) => {
  return requestWrapper(instance.patch, `/companies/${id}`, values);
};

const uploadCompanyLogo = async (id, file) => {
  const formData = new FormData();
  try {
    formData.append('file', file);
    return instance.patch(`/companies/${id}/logo`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  } catch (error) {
    return onError(error);
  }
};

/**
 * QUOTATION
 */

const postQuotation = (companyId, data) => {
  return requestWrapper(
    instance.post,
    `/companies/${companyId}/quotations`,
    data,
  );
};

const getQuotations = async (
  companyId,
  pageSize = 20,
  pageNumber = 0,
  grouped = true,
  filters = null,
  sort = null,
) => {
  return requestWrapper(
    instance.get,
    `/companies/${companyId}/quotations?page_size=${pageSize}` +
      `&page_number=${pageNumber}&grouped=${grouped}` +
      `${filters != null ? `&${qs.stringify({ filters })}` : ''}` +
      `${sort != null ? `&${qs.stringify({ sort })}` : ''}`,
  );
};

const getQuotationById = (companyId, id) => {
  return requestWrapper(
    instance.get,
    `/companies/${companyId}/quotations/${id}`,
  );
};

const cancelQuotation = (companyId, id) => {
  return requestWrapper(
    instance.patch,
    `/companies/${companyId}/quotations/${id}/cancel`,
  );
};

/**
 * TRANSPORT
 */

const postTransport = (companyId, data) => {
  return requestWrapper(
    instance.post,
    `/companies/${companyId}/transports`,
    data,
  );
};

const getTransports = async (
  companyId,
  pageSize = 20,
  pageNumber = 0,
  grouped = true,
  filters = null,
  sort = null,
) => {
  return requestWrapper(
    instance.get,
    `/companies/${companyId}/transports?page_size=${pageSize}` +
      `&page_number=${pageNumber}&grouped=${grouped}` +
      `${filters != null ? `&${qs.stringify({ filters })}` : ''}` +
      `${sort != null ? `&${qs.stringify({ sort })}` : ''}`,
  );
};

const getTransportById = (companyId, id) => {
  return requestWrapper(
    instance.get,
    `/companies/${companyId}/transports/${id}`,
  );
};

const getTransportByReference = (companyId, reference) => {
  return requestWrapper(
    instance.get,
    `/companies/${companyId}/transports/reference/${reference}`,
  );
};

/**
 * DOCUMENTS
 */

// TODO: Next route is not longer used for now, delete it if you delete the
//  backend management. (Now we use s3)
const uploadDocuments = (companyId, transportId, files) => {
  const formData = new FormData();

  try {
    for (const file of files) {
      formData.append('files', file);
    }
    return instance.post(
      `/companies/${companyId}/transports/${transportId}/files`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  } catch (error) {
    return onError(error);
  }
};

// TODO: Next route is not longer used for now, delete it if you delete the
//  backend management. (Now we use s3)
const downloadDocument = async (companyId, transportId, id) => {
  try {
    const response = await instance.get(
      `/companies/${companyId}/transports/${transportId}/files/${id}`,
      {
        responseType: 'blob',
      },
    );
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute(
      'download',
      decodeURIComponent(
        response.headers
          .get('Content-Disposition')
          .split('filename=')[1]
          .split(';')[0]
          .replaceAll('"', ''),
      ),
    ); // or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  } catch (error) {
    onError(error);
  }
};

const s3UploadDocuments = async (companyId, itemType, itemId, files) => {
  try {
    const { urls } = await requestWrapper(
      instance.post,
      `/companies/${companyId}/${itemType}/${itemId}/s3-files`,
      {
        files: files.map(({ name, type }) => ({ name, type })),
      },
    );

    return await Promise.all(
      files.map((file, idx) =>
        axios.put(urls[idx], file, {
          withCredentials: false,
          headers: {
            'Content-Type': file.type,
          },
        }),
      ),
    );
  } catch (error) {
    return onError(error);
  }
};

const s3DownloadDocument = async (
  companyId,
  itemType,
  itemId,
  fileId,
  filename,
) => {
  try {
    const { url } = await requestWrapper(
      instance.get,
      `/companies/${companyId}/${itemType}/${itemId}/s3-files/${fileId}`,
    );
    const s3Response = await axios.get(url, {
      responseType: 'blob',
    });
    const href = URL.createObjectURL(s3Response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename); // or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  } catch (error) {
    onError(error);
  }
};

/**
 * NOTIFICATIONS
 */

const getUserCompanyNotifications = (
  companyId,
  pageSize = 20,
  pageNumber = 0,
) => {
  return requestWrapper(
    instance.get,
    `/companies/${companyId}/notifications` +
      `?page_size=${pageSize}&page_number=${pageNumber}`,
  );
};

const patchReadUserNotifications = (companyId, notificationsIds) => {
  return requestWrapper(
    instance.patch,
    `/companies/${companyId}/notifications/read`,
    { notifications: notificationsIds },
  );
};

export default {
  login,
  logout,
  confirmEmail,
  confirmPhoneNumber,
  resendPhoneConfirmationCode,
  requestResetPassword,
  updatePassword,
  getCurrentUser,
  getUserById,
  updateUser,
  updateUserRole,
  uploadUserPicture,
  deactivateUser,
  getCurrentUserPreferences,
  updateCurrentUserPreferences,
  getCompanies,
  getCompanyUsers,
  updateCompany,
  uploadCompanyLogo,
  postQuotation,
  getQuotations,
  getQuotationById,
  cancelQuotation,
  postTransport,
  getTransports,
  getTransportById,
  getTransportByReference,
  uploadDocuments,
  downloadDocument,
  s3UploadDocuments,
  s3DownloadDocument,
  getUserCompanyNotifications,
  patchReadUserNotifications,
};
