import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { UserContext, CompanyContext } from '../../contexts';
import useLocalStorage from '../../hooks/useLocalStorage';
import RequestsManager from '../../helpers/axios-manager';

function CompanyProvider({ children }) {
  const { currentUser } = useContext(UserContext);
  const [companiesPagination, setCompaniesPagination] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useLocalStorage(
    'selectedCompany',
    null,
  );
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const memorizedCurrentCompany = useMemo(
    () => ({
      companiesPagination,
      setCompaniesPagination,
      companies,
      setCompanies,
      selectedCompany,
      setSelectedCompany,
      isLoading,
    }),
    [
      companiesPagination,
      companies,
      selectedCompany,
      setSelectedCompany,
      isLoading,
    ],
  );
  const queryCompany = searchParams.get('company');

  useEffect(() => {
    (async () => {
      if (currentUser != null) {
        let userCompanies;
        if (currentUser.role !== 'super-admin')
          userCompanies = currentUser.companies;
        else if (companies.length === 0) {
          const res = await RequestsManager.getCompanies(20, 0);
          userCompanies = res.items;
          setCompaniesPagination(res.pagination);
        } else userCompanies = companies ?? [];

        if (
          queryCompany != null &&
          selectedCompany != null &&
          queryCompany !== selectedCompany.id
        ) {
          const company = userCompanies.find(comp => comp.id === queryCompany);
          if (company != null) {
            setSelectedCompany(company);
            setCompanies(userCompanies);
            setIsLoading(false);
            return;
          }
        }
        if (selectedCompany == null && userCompanies.length > 0) {
          setSelectedCompany(userCompanies[0]);
          setSearchParams(
            previousSearchParams => {
              previousSearchParams.set('company', userCompanies[0].id);
              return previousSearchParams;
            },
            { replace: true },
          );
        } else {
          setSearchParams(
            previousSearchParams => {
              previousSearchParams.set('company', selectedCompany?.id ?? null);
              return previousSearchParams;
            },
            { replace: true },
          );
        }
        setCompanies(userCompanies);
      }
      setIsLoading(false);
    })();
  }, [
    companies,
    currentUser,
    queryCompany,
    selectedCompany,
    setSearchParams,
    setSelectedCompany,
  ]);

  return (
    <CompanyContext.Provider value={memorizedCurrentCompany}>
      {children}
    </CompanyContext.Provider>
  );
}

CompanyProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

CompanyProvider.defaultProps = {
  children: null,
};

export default CompanyProvider;
