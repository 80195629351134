import { setLocale } from 'yup';

setLocale({
  mixed: {
    default: 'field_invalid',
    required: ({ path }) => ({ key: 'field_required', values: { path } }),
    oneOf: ({ values }) => ({ key: 'field_must_be_one_of', values }),
    notOneOf: ({ values }) => ({ key: 'field_must_not_be_one_of', values }),
    notNull: 'field_not_null',
    notType: 'field_must_not_be_of_type',
    defined: 'field_must_be_defined',
  },
  number: {
    min: ({ min }) => ({ key: 'field_too_short', values: { min } }),
    max: ({ max }) => ({ key: 'field_too_big', values: { max } }),
    lessThan: ({ less }) => ({
      key: 'field_must_be_less_than',
      values: { less },
    }),
    moreThan: ({ more }) => ({
      key: 'field_must_be_more_than',
      values: { more },
    }),
    positive: ({ more }) => ({
      key: 'field_must_be_positive',
      values: { more },
    }),
    negative: ({ less }) => ({
      key: 'field_must_be_negative',
      values: { less },
    }),
    integer: 'field_must_be_integer',
  },
  string: {
    email: 'email_invalid',
    length: ({ length }) => ({
      key: 'field_must_have_length',
      values: { length },
    }),
    min: ({ path, min }) => ({
      key: 'string_field_too_short',
      values: { path, min },
    }),
    max: ({ max }) => ({ key: 'string_field_too_long', values: { max } }),
    matches: ({ regex }) => ({
      key: 'string_field_must_match_regex',
      values: { regex },
    }),
    url: ({ regex }) => ({
      key: 'string_field_must_be_url',
      values: { regex },
    }),
    uuid: ({ regex }) => ({
      key: 'string_field_must_be_uuid',
      values: { regex },
    }),
    datetime: 'string_field_must_be_datetime',
    datetime_offset: 'string_field_must_be_datetime_offset',
    datetime_precision: ({ precision }) => ({
      key: 'string_field_must_have_precision',
      values: { precision },
    }),
    trim: 'string_field_trim',
    lowercase: 'string_field_must_be_lowercase',
    uppercase: 'string_field_must_be_uppercase',
  },
  date: {
    min: ({ min }) => ({
      key: 'string_field_date_min',
      values: { min },
    }),
    max: ({ max }) => ({
      key: 'string_field_date_max',
      values: { max },
    }),
  },
  boolean: {
    isValue: 'boolean_field_value',
  },
  object: {
    noUnknown: 'object_no_unknown',
  },
  array: {
    length: ({ length }) => ({
      key: 'array_field_must_have_length',
      values: { length },
    }),
    min: ({ min }) => ({
      key: 'array_field_too_short',
      values: { min },
    }),
    max: ({ max }) => ({
      key: 'array_field_too_big',
      values: { max },
    }),
  },
  tuple: {
    notType: 'tuple_field_not_type',
  },
});
