export default {
  translation: {
    common: {
      loadMore: 'Charger plus',
      toasts: {
        updateLanguage: {
          loading: 'En cours...',
          success: 'Langue mise à jour avec succès.',
          error: 'Erreur durant le changement de langue.',
        },
      },
      quotation: {
        status: {
          pending: 'En cours de traitement',
          toBeConfirmed: 'À confirmer',
          declined: 'Refusé',
          canceled: 'Annulé',
          confirmed: 'Confirmé',
        },
      },
      transport: {
        status: {
          pending: 'En cours de traitement',
          upcoming: 'À venir',
          readyToGo: 'Au départ',
          inTransit: 'En transit',
          arrived: 'Arrivé',
          archived: 'Archivé',
          canceled: 'Annulé',
        },
      },
      sort: {
        title: 'Trier par',
        items: {
          etd: 'ETD',
          eta: 'ETA',
          reference: 'Référence',
        },
      },
      filter: {
        title: 'Ajouter un filtre',
        items: {
          departureCountry: 'Lieu de départ',
          arrivalCountry: "Lieu d'arrivée",
        },
      },
    },
    pages: {
      activateAccount: {
        title: 'Votre compte est activé',
        error:
          "Une erreur s'est produite lors de l'activation de votre compte, " +
          "merci de vérifier vos emails. Si vous n'avez pas reçu d'email, " +
          'merci de contacter notre équipe.',
        loginLink: 'Aller à la page de connexion',
      },
      login: {
        buttons: {
          getQuote: 'Obtenir une cotation',
        },
        form: {
          fields: {
            email: 'Adresse email',
            password: 'Mot de passe',
          },
          buttons: {
            submit: 'Connexion',
          },
        },
        links: {
          forgotPassword: 'Mot de passe oublié',
        },
      },
      forgotPassword: {
        form: {
          buttons: {
            submit: 'Confirmer la demande',
          },
        },
      },
      updatePassword: {
        title: 'Réinitialiser votre mot de passe',
        form: {
          fields: {
            confirmPassword: 'Confirmez votre mot de passe',
          },
          buttons: {
            submit: 'Mettre à jour le mot de passe',
          },
        },
      },
      settings: {
        sections: {
          information: {
            title: 'Vos informations',
            form: {
              fields: {
                firstName: 'Prénom',
                lastName: 'Nom',
                phone: 'Numéro de téléphone',
                email: 'Email',
                position: 'Poste',
              },
            },
            profilePicture: 'Photo de profil :',
          },
          alerts: {
            title: 'Vos alertes',
            types: {
              notifications: 'Notifications',
              email: 'Mail',
              sms: 'SMS',
            },
            createTransport: 'Création du transport',
            createQuotation: 'Création de cotation',
            transportEvent: 'Évènement transport',
            arrivalNotice: "Avis d'arrivée",
            newMessage: 'Nouveau message',
          },
          members: {
            title: 'Membres',
            searchPlaceholder: 'Rechercher...',
            roles: {
              admin: 'Administrateur',
              user: 'Utilisateur',
              guest: 'Invité',
            },
            parameter: {
              disable: 'Désactiver',
            },
          },
          company: {
            title: 'Votre entreprise',
            form: {
              fields: {
                name: 'Nom',
                country: 'Pays',
                address: 'Adresse',
              },
            },
            logo: 'Logo :',
          },
        },
        buttons: {
          cancel: 'Annuler',
          save: 'Mettre à jour',
        },
      },
      transportsWithColumns: {
        header: {
          searchBarPlaceholder: 'Rechercher',
        },
      },
      transportsWithLeftPanel: {
        searchBarPlaceholder: 'Rechercher',
        return: 'Retour',
        buttons: {
          inProgress: 'En cours',
          history: 'Historique',
          addFilter: 'Ajouter un filtre',
          requestReturn: 'Demander un retour',
        },
        emptyTransportsList:
          'Aucun transport à afficher, pour commencer merci de créer un ' +
          'transport',
        emptySelectedTransport: 'Aucun transport sélectionné',
        transportCard: {
          stepDateFormat: 'DD MMMM YYYY, HH:mm',
        },
        dateFormat: 'DD/MM',
        panels: {
          trip: {
            dateFormat: 'DD/MM HH:mm',
          },
          details: {
            title: 'Détails du transport',
          },
          documents: {
            title: 'Vos documents',
          },
          events: {
            title: 'Évènements',
            dateFormat: 'DD/MM/YYYY à HH:mm',
            types: {
              upcoming: 'Transport créé',
            },
          },
          map: {
            estimatedDelivery: 'Livraison estimée',
            price: 'Prix',
            from: 'De :',
            to: 'À :',
            location: 'Position:',
            dateFormat: 'DD/MM',
            hourFormat: 'HH:mm',
          },
          packaging: {
            titleTotal: 'Colisage total',
            title: 'Colisage',
            details: 'Détails',
            package: 'Colis {{number}}',
            quantity: 'Quantité',
            dimensions: 'Dimensions',
            grossWeight: 'Poids brut',
            volumetricWeight: 'Poids volumique',
            goodsNature: 'Nature de la marchandise',
            contents: 'Contenus',
          },
        },
      },
      requests: {
        titles: {
          transport_zero: 'demande de transport',
          transport_one: 'demande de transport',
          transport_other: 'demandes de transport',
          quotation_zero: 'demande de cotation',
          quotation_one: 'demande de cotation',
          quotation_other: 'demandes de cotation',
        },
        card: {
          actions: {
            pending: 'En cours de traitement',
            validate: 'Valider',
            decline: 'Refuser',
            cancel: 'Annuler',
            toasts: {
              cancel: {
                loading: 'En cours...',
                success: 'Cotation annulée.',
                error: "Erreur durant l'annulation de la cotation",
              },
            },
          },
        },
      },
    },
    menu: {
      top: {
        items: {
          myCompany: 'Mon entreprise',
          addressesBook: 'Annuaire',
        },
      },
      bottom: {
        items: {
          logout: 'Se déconnecter',
        },
      },
    },
    header: {
      noCompany: 'Aucune société',
      center: {
        requests: 'Demandes',
        transports: 'Transports',
        history: 'Historique',
      },
      buttons: {
        createTransport: 'Créer un transport',
      },
    },
    modals: {
      confirmPhoneNumber: {
        title: 'Entrez le code reçu pour confirmer votre numéro de téléphone',
        placeholder: 'Entrez le code ici',
        confirm: 'Confirmer',
        resendCodeButton: 'Renvoyer un code',
        resendCodeLabel_zero:
          'Vous pourrez renvoyer un code dans {{count}} seconde',
        resendCodeLabel_one:
          'Vous pourrez renvoyer un code dans {{count}} seconde',
        resendCodeLabel:
          'Vous pourrez renvoyer un code dans {{count}} secondes',
      },
      createTransport: {
        title: 'Nouvelle demande',
        steps: {
          departureInformation: 'Informations d’enlèvement',
          arrivalInformation: 'Informations de livraison',
          packageInformation: 'Informations de colisage',
          transportInformation: 'Informations de transport',
          contactsInformation: 'Contacts',
          departureAddress: {
            title: 'Adresse de départ',
            searchBarPlaceholder: "D'où part votre marchandise ?",
          },
          departureDate: {
            title: 'Date de départ',
            hourLabel: 'Heure de départ :',
          },
          arrivalAddress: {
            title: "Adresse d'arrivée souhaitée",
            searchBarPlaceholder: 'Où souhaitez vous livrer ?',
          },
          arrivalDate: {
            title: "Date d'arrivée souhaitée",
            hourLabel: "Heure d'arrivée souhaitée :",
          },
          addPackage: {
            total: {
              title: 'Total :',
              piecesAbbreviation_zero: 'pc.',
              piecesAbbreviation_one: 'pc.',
              piecesAbbreviation_other: 'pcs.',
              cubeMeters: 'm³.',
            },
            sizesUnitTitle: 'Dimensions',
            weightsUnitTitle: 'poids',
            characteristicsTitle: 'Caractéristiques',
            descriptionTitle: 'Nature de la marchandise',
            contentsTitle: 'Contenus',
            buttons: {
              addPackageTop: 'Nouveau colis',
              selectPackage: 'Colis {{idx}}',
            },
            package: {
              placeholders: {
                quantity:
                  '$t(pages.transportsWithLeftPanel.panels.packaging.quantity)',
                height: 'Hauteur',
                length: 'Longueur',
                width: 'Largeur',
                weight: 'Poids',
                description: 'Ajouter une description',
                content: 'Ajouter un contenu',
              },
              switches: {
                dangerous: 'Dangereux',
                withCare: 'Fragile',
              },
            },
          },
          information: {
            transportTitle: 'Intitulé du transport',
            transportTitlePlaceholder: 'Nommez votre transport',
            tagTitle: 'Tag',
            tagPlaceholder: 'XXXXX',
            incoterm: 'Incoterm',
            select: 'Sélectionner',
            specialsRequestsTitle: 'Requêtes spéciales',
            textAreaPlaceholder: 'Écrire commentaire',
          },
          contacts: {
            titleDeparture: "Contact à l'enlèvement",
            titleArrival: 'Contact à la livraison',
            form: {
              fields: {
                company: 'Société',
                lastName: 'Nom',
                firstName: 'Prénom',
                phone: 'Téléphone',
                email: 'Adresse email',
                comment: 'Commentaire',
              },
            },
          },
          summary: {
            title: 'Résumé',
            departure: 'Départ',
            arrival: 'Arrivée',
            dateFormat: 'DD/MM, HH:mm',
            others: 'Autres',
            tag: '$t(modals.createTransport.steps.information.tagTitle) :',
            incoterm: '$t(modals.createTransport.steps.information.incoterm) :',
            specialsRequests:
              '$t(modals.createTransport.steps.information.specialsRequestsTitle) :',
            noSpecialRequest: 'Aucune requête spéciale',
            buttons: {
              requestQuotation: 'Demander une cotation',
              triggerTransport: 'Déclencher le transport',
            },
          },
        },
        starredAddresses: 'Vos adresses préférées :',
        buttons: {
          next: 'Suivant',
          end: 'Terminé',
        },
      },
      conversations: {
        title: 'Messages',
        hourFormat: 'HH:mm',
        inputPlaceholder: 'Écrivez votre message...',
      },
      notifications: {
        title: 'Notifications',
        empty: 'Aucune notification',
        dateFormat: 'DD/MM/YYYY à HH:mm',
        buttons: {
          readAll: 'Marquer comme lu',
          showMore: 'Voir plus',
        },
        items: {
          types: {
            quotation: 'de la cotation',
            transport: 'du transport',
          },
          transportEvent:
            'Un nouvel évènement à été ajouté à propos {{itemType}} : {{name}}',
        },
      },
      requests: {
        header: {
          summary: 'Résumé',
          quotation: 'Cotation',
          documents: 'Documents',
        },
      },
    },
    alerts: {
      waiting: 'En cours...',
      successfullyLogged: 'Vous êtes maintenant connecté',
      confirmedRequest: 'Demande validée',
      userInformationUpdated: 'Informations sauvegardées',
      userNotificationsPreferencesUpdated:
        'Préférences de notifications sauvegardées',
      newPhoneConfirmationCodeSent: 'Un nouveau code vous à été envoyé',
      phoneConfirmed: 'Votre numéro de téléphone est confirmé',
      userDisabled: "L'utilisateur à été désactivé",
      userRoleUpdated: "L'utilisateur est maintenant : {{role}}",
      passwordUpdated: 'Votre mot de passe à correctement été mis à jour',
      emailSent: 'Un email vous à été envoyé',
      pleaseSelectInList:
        'Merci de sélectionner un élément dans la liste des suggestions',
      successfullyUploaded: 'Document(s) sauvegardés',
    },
    errors: {
      form: {
        field_required: 'Le champ {{path}} est obligatoire.',
        email_invalid: 'Adresse email invalide.',
        invalid_phone_number: 'Numéro de téléphone invalide.',
        string_field_too_short:
          '{{path}} doit contenir au minimum {{min}} caractères.',
        password_rules:
          'Votre mot de passe doit contenir au moins 12 caractères, dont au ' +
          'moins 1 lettre minuscule, 1 lettre majuscule, 1 chiffre, et un ' +
          'caractère spécial.',
        passwords_must_match: 'Les mots de passe ne correspondent pas.',
      },
      unknown: 'Erreur inconnue.',
      'Internal Server Error': 'Erreur liée au serveur.',
      'You are not allowed to access to this':
        "Vous n'avez pas l'autorisation d'effectuer cette action",
      'User not found': 'Utilisateur inconnu.',
      'Wrong credentials': "L'email et le mot de passe ne correspondent pas.",
      'Invalid request parameters': 'Paramètre invalide.',
      'Unable to change the role of the only admin of this company':
        "Impossible de changer le role du seul administrateur de l'entreprise",
      'Code invalid': "Le code n'est pas valide",
      'Last code request less than 30 seconds, please wait':
        'Votre dernière demande était il y a moins de 30 secondes, merci de réessayer dans quelques instants.',
      'Rate limit exceeded, retry in 1 minute': 'Trop de requêtes.',
    },
  },
};
