import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { WebsocketContext } from '../../contexts';
import WsConnection from '../WsConnection';
import config from '../../config/config';

function WebsocketProvider({ children }) {
  const [connected, setConnected] = useState(true);
  const [onMessage, setOnMessage] = useState(() => () => {});
  const [onChannelOpened, setOnChannelOpened] = useState(() => () => {});
  const [channel, setChannel] = useState(null);

  const openChannel = useCallback(
    inChannel => {
      setChannel(() => {
        return inChannel;
      });
    },
    [setChannel],
  );

  const memorizedWebsocketData = useMemo(
    () => ({
      connected,
      onMessage,
      setOnMessage,
      channel,
      onChannelOpened,
      setOnChannelOpened,
    }),
    [channel, connected, onChannelOpened, onMessage],
  );

  return (
    <WebsocketContext.Provider value={memorizedWebsocketData}>
      <WsConnection
        url={`${config.apiUrl.replace('http', 'ws')}/sockets`}
        onOpen={() => setConnected(true)}
        onMessage={onMessage}
        onClose={() => setConnected(false)}
        onError={err => {
          setConnected(false);
          // eslint-disable-next-line no-console
          console.error(err);
        }}
        onChannelOpened={openChannel}
      />
      {children}
    </WebsocketContext.Provider>
  );
}

WebsocketProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

WebsocketProvider.defaultProps = {
  children: null,
};

export default WebsocketProvider;
