import * as CookieConsent from 'vanilla-cookieconsent';

export default {
  root: document.body,
  revision: 0,
  // onModalReady: ({ modalName, modal }) => {
  //   // do something
  // },
  disablePageInteraction: false,
  cookie: {
    name: 'cc_cookie',
    domain: window.location.hostname,
    path: '/',
    expiresAfterDays: 182,
    sameSite: 'Strict',
    useLocalStorage: false,
  },
  // onFirstConsent: ({ cookie }) => {
  //   // do something
  // },
  // onConsent: ({ cookie }) => {
  //   // do something
  // },
  onChange: ({
    // cookie,
    changedCategories,
    // changedPreferences,
    changedServices,
  }) => {
    if (changedCategories.includes('analytics')) {
      if (CookieConsent.acceptedCategory('analytics')) {
        // the analytics category was just enabled
      } else {
        // the analytics category was just disabled
      }

      if (changedServices.analytics.includes('Google Analytics')) {
        if (CookieConsent.acceptedService('Google Analytics', 'analytics')) {
          // Google Analytics was just enabled
        } else {
          // Google Analytics was just disabled
        }
      }
    }
  },
  // onModalShow: ({ modalName }) => {
  //   // do something
  // },
  // onModalHide: ({ modalName }) => {
  //   // do something
  // },
  guiOptions: {
    consentModal: {
      layout: 'box',
      position: 'bottom right',
      flipButtons: false,
      equalWeightButtons: true,
    },
    preferencesModal: {
      layout: 'box',
      position: 'right',
      flipButtons: false,
      equalWeightButtons: true,
    },
  },
  categories: {
    necessary: {
      enabled: true, // this category is enabled by default
      readOnly: true, // this category cannot be disabled
    },
    analytics: {},
  },
  language: {
    default: 'en',
    translations: {
      en: {
        consentModal: {
          title: 'We use cookies',
          description: 'Cookie modal description',
          acceptAllBtn: 'Accept all',
          acceptNecessaryBtn: 'Reject all',
          showPreferencesBtn: 'Manage Individual preferences',
        },
        preferencesModal: {
          title: 'Manage cookie preferences',
          acceptAllBtn: 'Accept all',
          acceptNecessaryBtn: 'Reject all',
          savePreferencesBtn: 'Accept current selection',
          closeIconLabel: 'Close modal',
          sections: [
            {
              title: 'Somebody said ... cookies?',
              description: 'I want one!',
            },
            {
              title: 'Strictly Necessary cookies',
              description:
                'These cookies are essential for the proper functioning of the website and cannot be disabled.',

              // this field will generate a toggle linked to the 'necessary' category
              linkedCategory: 'necessary',
            },
            {
              title: 'Performance and Analytics',
              description:
                'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
              linkedCategory: 'analytics',
            },
            {
              title: 'More information',
              description:
                'For any queries in relation to my policy on cookies and your choices, please <a href="#contact-page">contact us</a>',
            },
          ],
        },
      },
    },
  },
};
