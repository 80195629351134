import React from 'react';
import { Toaster } from 'react-hot-toast';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, computedFonts } from '../helpers/styles';
import { CheckCircleIcon, CloseIcon } from '../assets';

function Toast({ className }) {
  return (
    <Toaster
      position="bottom-center"
      reverseOrder={false}
      gutter={8}
      containerClassName={className}
      toastOptions={{
        className: 'toast',
        success: {
          icon: <CheckCircleIcon />,
          style: {
            background: colors.green,
          },
        },
        error: {
          icon: <CloseIcon className="close-icon" />,
          style: {
            background: colors.strackerRed,
          },
        },
      }}
    />
  );
}

Toast.propTypes = {
  className: PropTypes.string.isRequired,
};

const StyledToast = styled(Toast)`
  margin-bottom: 16px !important;

  .toast {
    color: white;
    ${computedFonts.quotationInfo};

    .close-icon {
      width: 32px;
      height: 32px;

      path {
        stroke: white;
      }
    }
  }
`;

export default StyledToast;
