import { useEffect } from 'react';

// TODO: Finish this properly (and remove all logs).
/* eslint-disable no-console */

function WsConnection({
  url,
  onOpen,
  onChannelOpened,
  onMessage,
  onClose,
  onError,
}) {
  useEffect(() => {
    const ws = new WebSocket(url);

    ws.onopen = () => {
      console.log('WebSocket connection opened');
      onOpen();
      onChannelOpened(message => {
        if (ws.readyState === 1) {
          ws.send(message);
        }
      });
    };

    ws.onmessage = event => {
      console.log('WebSocket message received:', typeof event.data, event.data);
      try {
        onMessage(JSON.parse(event.data));
      } catch (e) {
        console.error('Invalid JSON: ', event.data, e);
      }
    };

    ws.onclose = event => {
      console.log('WebSocket connection closed', ws.readyState, event);
      onClose();
    };

    ws.onerror = error => {
      console.debug('WebSocket error: ', error);
      if (ws.readyState === 1) {
        onError(error);
        ws.close(error);
      }
    };

    return function cleanup() {
      console.debug('WebSocket connection cleanup');
      ws.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onMessage]);

  return false;
}

export default WsConnection;
