import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { en, fr } from './index';
import config from '../config/config';

(async () =>
  i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      detection: {
        convertDetectedLanguage: lang => lang.split('-')[0],
      },
      resources: { en, fr },
      fallbackLng: 'fr',
      interpolation: {
        // react already safes from xss =>
        // https://www.i18next.com/translation-function/interpolation#unescape
        escapeValue: false,
      },
      debug: config.debug,
    }))();
