import './app.css';
import { createGlobalStyle } from 'styled-components';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import { colors, fontFamilies } from './helpers/styles';

export default createGlobalStyle`
  html, body, body>div, #root>div {
    width: 100%;
    height: 100%;
    margin: auto;
  }

  .pac-container {
    /* Google places autocomplete design */
    height: auto;
  }

  /** Cookie consent light color-scheme **/
  :root {
    --cc-bg: #fff;
    --cc-primary-color: #2c2f31;
    --cc-secondary-color: #5e6266;
    --cc-btn-primary-bg: linear-gradient(
        to right,
        ${colors.blue}cc,
        ${colors.purple}cc
    );
    --cc-btn-primary-color: #fff;
    --cc-btn-primary-border-color: var(--cc-btn-primary-bg);
    --cc-btn-primary-hover-bg: ${colors.strackerLightBlue};
    --cc-btn-primary-hover-color: #fff;
    --cc-btn-primary-hover-border-color: var(--cc-btn-primary-hover-bg);
    --cc-btn-secondary-bg: #eaeff2;
    --cc-btn-secondary-color: var(--cc-primary-color);
    --cc-btn-secondary-border-color: var(--cc-btn-secondary-bg);
    --cc-btn-secondary-hover-bg: #d4dae0;
    --cc-btn-secondary-hover-color: #000;
    --cc-btn-secondary-hover-border-color: #d4dae0;
    --cc-separator-border-color: #f0f4f7;
    --cc-toggle-on-bg: var(--cc-btn-primary-bg);
    --cc-toggle-off-bg: #667481;
    --cc-toggle-on-knob-bg: #fff;
    --cc-toggle-off-knob-bg: var(--cc-toggle-on-knob-bg);
    --cc-toggle-enabled-icon-color: var(--cc-bg);
    --cc-toggle-disabled-icon-color: var(--cc-bg);
    --cc-toggle-readonly-bg: #d5dee2;
    --cc-toggle-readonly-knob-bg: #fff;
    --cc-toggle-readonly-knob-icon-color: var(--cc-toggle-readonly-bg);
    --cc-section-category-border: var(--cc-cookie-category-block-bg);
    --cc-cookie-category-block-bg: #f0f4f7;
    --cc-cookie-category-block-border: #f0f4f7;
    --cc-cookie-category-block-hover-bg: #e9eff4;
    --cc-cookie-category-block-hover-border: #e9eff4;
    --cc-cookie-category-expanded-block-bg: transparent;
    --cc-cookie-category-expanded-block-hover-bg: #dee4e9;
    --cc-overlay-bg: rgb(0 0 0 / 65%);
    --cc-webkit-scrollbar-bg: var(--cc-section-category-border);
    --cc-webkit-scrollbar-hover-bg: var(--cc-btn-primary-hover-bg);
    --cc-footer-bg: var(--cc-btn-secondary-bg);
    --cc-footer-color: var(--cc-secondary-color);
    --cc-footer-border-color: #e4eaed;
    --cc-font-family: ${fontFamilies.openSans};
    --cc-modal-border-radius: .5rem;
    --cc-btn-border-radius: .4rem;
    --cc-modal-transition-duration: .25s;
    --cc-modal-margin: 1rem;
    --cc-z-index: 2147483647;
  }

  /** Cookie consent dark color-scheme **/
  /* stylelint-disable-next-line selector-class-pattern */
  .cc--darkmode {
    --cc-bg: #161a1c;
    --cc-primary-color: #ebf3f6;
    --cc-secondary-color: #aebbc5;
    --cc-btn-primary-bg: #c2d0e0;
    --cc-btn-primary-color: var(--cc-bg);
    --cc-btn-primary-border-color: var(--cc-btn-primary-bg);
    --cc-btn-primary-hover-bg: #98a7b6;
    --cc-btn-primary-hover-color: #000;
    --cc-btn-primary-hover-border-color: var(--cc-btn-primary-hover-bg);
    --cc-btn-secondary-bg: #242c31;
    --cc-btn-secondary-color: var(--cc-primary-color);
    --cc-btn-secondary-border-color: var(--cc-btn-secondary-bg);
    --cc-btn-secondary-hover-bg: #353d43;
    --cc-btn-secondary-hover-color: #fff;
    --cc-btn-secondary-hover-border-color: var(--cc-btn-secondary-hover-bg);
    --cc-separator-border-color: #222a30;
    --cc-toggle-on-bg: var(--cc-btn-primary-bg);
    --cc-toggle-off-bg: #525f6b;
    --cc-toggle-on-knob-bg: var(--cc-btn-primary-color);
    --cc-toggle-off-knob-bg: var(--cc-btn-primary-color);
    --cc-toggle-enabled-icon-color: var(--cc-btn-primary-color);
    --cc-toggle-disabled-icon-color: var(--cc-btn-primary-color);
    --cc-toggle-readonly-bg: #343e45;
    --cc-toggle-readonly-knob-bg: #5f6b72;
    --cc-toggle-readonly-knob-icon-color: var(--cc-toggle-readonly-bg);
    --cc-section-category-border: #1e2428;
    --cc-cookie-category-block-bg: #1e2428;
    --cc-cookie-category-block-border: var(--cc-section-category-border);
    --cc-cookie-category-block-hover-bg: #242c31;
    --cc-cookie-category-block-hover-border: #232a2f;
    --cc-cookie-category-expanded-block-bg: transparent;
    --cc-cookie-category-expanded-block-hover-bg: var(--cc-toggle-readonly-bg);
    --cc-overlay-bg: rgb(0 0 0 / 65%);
    --cc-webkit-scrollbar-bg: var(--cc-section-category-border);
    --cc-webkit-scrollbar-hover-bg: var(--cc-btn-primary-hover-bg);
    --cc-footer-bg: #0c0e0f;
    --cc-footer-color: var(--cc-secondary-color);
    --cc-footer-border-color: #060809;
    --cc-font-family: ${fontFamilies.openSans};
    --cc-modal-border-radius: .5rem;
    --cc-btn-border-radius: .4rem;
    --cc-modal-transition-duration: .25s;
    --cc-modal-margin: 1rem;
    --cc-z-index: 2147483647;

    #cc-main {
      color-scheme: dark;
    }
  }
`;
