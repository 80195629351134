import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UserContext } from './contexts';
import CompanyProvider from './components/Providers/CompanyProvider';
import WebsocketProvider from './components/Providers/WebsocketProvider';

function LoginWrapper({ Component, mustBeLogged }) {
  const { currentUser } = useContext(UserContext);
  const logged = currentUser != null;
  if (mustBeLogged && logged)
    return (
      <CompanyProvider>
        <WebsocketProvider>
          <Component />
        </WebsocketProvider>
      </CompanyProvider>
    );
  if (!mustBeLogged && !logged) {
    return <Component />;
  }

  return (
    <Navigate
      replace
      to={mustBeLogged ? '/login' : `/transports${window.location.search}`}
    />
  );
}

LoginWrapper.propTypes = {
  Component: PropTypes.elementType.isRequired,
  mustBeLogged: PropTypes.bool,
};

LoginWrapper.defaultProps = {
  mustBeLogged: true,
};

export default LoginWrapper;
