import './translations/i18n';
import 'dayjs/locale/fr';
import React, { useEffect, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import * as CookieConsent from 'vanilla-cookieconsent';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import cookieConsentConfiguration from './config/cookie-consent';
import GlobalStyle from './GlobalStyle';
import LoadingScreen from './components/LoadingScreen';
import UserProvider from './components/Providers/UserProvider';
import AppRoutes from './AppRoutes';
import Toast from './components/Toast';

dayjs.extend(utc);
dayjs.extend(advancedFormat);

function App() {
  useEffect(() => {
    // To enable disable cookie consent darkmode:
    // document.documentElement.classList.add('cc--darkmode');
    (async () => {
      await CookieConsent.run(cookieConsentConfiguration);
    })();
  }, []);

  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        <Suspense fallback={<LoadingScreen />}>
          <UserProvider>
            <AppRoutes />
            <Toast />
          </UserProvider>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
