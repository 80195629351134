import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../contexts';
import RequestsManager from '../../helpers/axios-manager';

function UserProvider({ children }) {
  const { i18n } = useTranslation();
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const memorizedCurrentUser = useMemo(
    () => ({
      currentUser,
      setCurrentUser,
      isLoading,
    }),
    [currentUser, isLoading],
  );

  useEffect(() => {
    (async () => {
      const user = await RequestsManager.getCurrentUser();
      setCurrentUser(user);
      if (user != null) {
        await i18n.changeLanguage(user.language);
      }
      setIsLoading(false);
    })();
  }, [i18n]);

  return (
    <UserContext.Provider value={memorizedCurrentUser}>
      {children}
    </UserContext.Provider>
  );
}

UserProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

UserProvider.defaultProps = {
  children: null,
};

export default UserProvider;
