import { createContext } from 'react';

export const UserContext = createContext(null);

export const CompanyContext = createContext(null);

export const ModalContext = createContext(null);

export const TransportsContext = createContext(null);

export const WebsocketContext = createContext(null);
