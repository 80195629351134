import './translations/i18n';
import 'dayjs/locale/fr';
import React, { useEffect, lazy, useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import * as CookieConsent from 'vanilla-cookieconsent';
import cookieConsentConfiguration from './config/cookie-consent';
import { UserContext } from './contexts';
import LoadingScreen from './components/LoadingScreen';
import LoginWrapper from './LoginWrapper';

const ActivateAccount = lazy(() => import('./pages/ActivateAccount'));
const Login = lazy(() => import('./pages/Login'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const UpdatePassword = lazy(() => import('./pages/UpdatePassword'));
const Details = lazy(() => import('./pages/Transports/Details'));
const Requests = lazy(() => import('./pages/Requests'));
const Transports = lazy(() => import('./pages/Transports/Transports'));
const Settings = lazy(() => import('./pages/Settings'));
const TransportsHistory = lazy(
  () => import('./pages/Transports/TransportsHistory'),
);

function AppRoutes() {
  const { isLoading, currentUser } = useContext(UserContext);

  useEffect(() => {
    // To enable disable cookie consent darkmode:
    // document.documentElement.classList.add('cc--darkmode');
    (async () => {
      await CookieConsent.run(cookieConsentConfiguration);
    })();
  }, []);

  return (
    (isLoading && <LoadingScreen />) || (
      <Routes>
        <Route
          path="/activate"
          element={
            <LoginWrapper mustBeLogged={false} Component={ActivateAccount} />
          }
        />
        <Route
          path="login"
          element={<LoginWrapper mustBeLogged={false} Component={Login} />}
        />
        <Route
          path="forgot-password"
          element={
            <LoginWrapper mustBeLogged={false} Component={ForgotPassword} />
          }
        />
        <Route
          path="update-password"
          element={
            <LoginWrapper mustBeLogged={false} Component={UpdatePassword} />
          }
        />
        <Route
          path="requests"
          element={<LoginWrapper Component={Requests} />}
        />
        <Route
          path="transports"
          element={<LoginWrapper Component={Transports} />}
        />
        <Route
          path="history"
          element={<LoginWrapper Component={TransportsHistory} />}
        />
        <Route path="details" element={<LoginWrapper Component={Details} />} />
        <Route
          path="settings"
          element={<LoginWrapper Component={Settings} />}
        />
        <Route
          path="*"
          element={
            <Navigate
              replace
              to={
                currentUser != null
                  ? `/transports${window.location.search}`
                  : '/login'
              }
            />
          }
        />
      </Routes>
    )
  );
}

export default AppRoutes;
