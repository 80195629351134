export default {
  translation: {
    common: {
      loadMore: 'Load more',
      toasts: {
        updateLanguage: {
          loading: 'In progress...',
          success: 'Language successfully updated.',
          error: 'Error during language update.',
        },
      },
      quotation: {
        status: {
          pending: 'Pending',
          toBeConfirmed: 'To be confirmed',
          declined: 'Declined',
          canceled: 'Canceled',
          confirmed: 'Confirmed',
        },
      },
      transport: {
        status: {
          pending: 'Pending',
          upcoming: 'Upcoming',
          readyToGo: 'Ready to go',
          inTransit: 'In transit',
          arrived: 'Arrived',
          archived: 'Archived',
          canceled: 'Canceled',
        },
      },
      sort: {
        title: 'Sort by',
        items: {
          etd: 'ETD',
          eta: 'ETA',
          reference: 'Reference',
        },
      },
      filter: {
        title: 'Add a filter',
        items: {
          departure: 'Departure country',
          arrival: 'Arrival country',
        },
      },
    },
    pages: {
      activateAccount: {
        title: 'Account activated',
        error:
          'Error during activate your account, please check your emails. ' +
          "If you didn't received a new link please contact us.",
        loginLink: 'Go to login page',
      },
      login: {
        buttons: {
          getQuote: 'Get a quote',
        },
        form: {
          fields: {
            email: 'Email',
            password: 'Password',
          },
          buttons: {
            submit: 'Login',
          },
        },
        links: {
          forgotPassword: 'Forgot password',
        },
      },
      forgotPassword: {
        form: {
          buttons: {
            submit: 'Confirm the request',
          },
        },
      },
      updatePassword: {
        title: 'Update your password',
        form: {
          fields: {
            confirmPassword: 'Confirm your password',
          },
          buttons: {
            submit: 'Update password',
          },
        },
      },
      settings: {
        sections: {
          information: {
            title: 'Your information',
            form: {
              fields: {
                firstName: 'First name',
                lastName: 'Last name',
                phone: 'Phone number',
                email: 'Email',
                position: 'Position',
              },
            },
            profilePicture: 'Profile picture:',
          },
          alerts: {
            title: 'Your alerts',
            types: {
              notifications: 'Notifications',
              email: 'Email',
              sms: 'SMS',
            },
            createTransport: 'Transport creation',
            createQuotation: 'Quotation creation',
            transportEvent: 'Transport event',
            arrivalNotice: 'Arrival notice',
            newMessage: 'New message',
          },
          members: {
            title: 'Members',
            searchPlaceholder: 'Search...',
            roles: {
              admin: 'Admin',
              user: 'User',
              guest: 'Guest',
            },
            parameter: {
              disable: 'Disable',
            },
          },
          company: {
            title: 'Your company',
            form: {
              fields: {
                name: 'Name',
                country: 'country',
                address: 'Address',
              },
            },
            logo: 'Logo :',
          },
        },
        buttons: {
          cancel: 'Cancel',
          save: 'Save',
        },
      },
      transportsWithColumns: {
        header: {
          searchBarPlaceholder: 'Search',
        },
      },
      transportsWithLeftPanel: {
        searchBarPlaceholder: 'Search',
        return: 'Return',
        buttons: {
          inProgress: 'In progress',
          history: 'History',
          addFilter: 'Add a filter',
          requestReturn: 'Request a return',
        },
        emptyTransportsList:
          'No transport to display, to begin, create a transport',
        emptySelectedTransport: 'No selected transport',
        transportCard: {
          stepDateFormat: 'MMMM Do YYYY, hh:mm A',
        },
        dateFormat: 'MM/DD',
        panels: {
          trip: {
            dateFormat: 'MM/DD hh:mm A',
          },
          details: {
            title: 'Transport details',
          },
          documents: {
            title: 'Your documents',
          },
          events: {
            title: 'Events',
            dateFormat: 'MM/DD/YYYY [at] hh:mm A',
            types: {
              upcoming: 'Transport created',
            },
          },
          map: {
            estimatedDelivery: 'Estimated delivery',
            price: 'Price',
            from: 'From:',
            to: 'To:',
            location: 'Location:',
            dateFormat: 'MM/DD',
            hourFormat: 'HH:mm',
          },
          packaging: {
            title: 'Packaging',
            details: 'Details',
            package: 'Package {{number}}',
            quantity: 'Quantity',
            dimensions: 'Dimensions',
            grossWeight: 'Gross weight',
            volumetricWeight: 'Volumetric weight',
            goodsNature: 'Nature of the goods',
            contents: 'Contents',
          },
        },
      },
      requests: {
        titles: {
          transport_zero: 'transport request',
          transport_one: 'transport request',
          transport_other: 'transport requests',
          quotation_zero: 'quotation request',
          quotation_one: 'quotation request',
          quotation_other: 'quotation requests',
        },
        card: {
          actions: {
            pending: 'Pending',
            validate: 'Validate',
            decline: 'Decline',
            cancel: 'Cancel',
            toasts: {
              cancel: {
                loading: 'Processing...',
                success: 'Quotation successfully canceled.',
                error: 'Error during cancel quotation',
              },
            },
          },
        },
      },
    },
    menu: {
      top: {
        items: {
          myCompany: 'My company',
          addressesBook: 'Adresses book',
        },
      },
      bottom: {
        items: {
          logout: 'Logout',
        },
      },
    },
    header: {
      noCompany: 'No company',
      center: {
        requests: 'Requests',
        transports: 'Transports',
        history: 'History',
      },
      buttons: {
        createTransport: 'Create a transport',
      },
    },
    modals: {
      confirmPhoneNumber: {
        title: 'Entre the received code to confirm your phone number',
        placeholder: 'Entrez le code ici',
        confirm: 'Confirm',
        resendCodeButton: 'Resend a code',
        resendCodeLabel_zero:
          'You will be able to resend a code in {{count}} second',
        resendCodeLabel_one:
          'You will be able to resend a code in {{count}} second',
        resendCodeLabel_other:
          'You will be able to resend a code in {{count}} seconds',
      },
      createTransport: {
        title: 'New transport',
        steps: {
          departureInformation: 'Departure information',
          arrivalInformation: 'Arrival information',
          packageInformation: 'Packages information',
          transportInformation: 'Transport information',
          departureAddress: {
            title: 'Departure address',
            searchBarPlaceholder: 'Where does your merchandise come from?',
          },
          departureDate: {
            title: 'Departure date',
            hourLabel: 'Departure hour:',
          },
          arrivalAddress: {
            title: 'Wanted arrival address',
            searchBarPlaceholder: 'Where do you want to deliver?',
          },
          arrivalDate: {
            title: 'Wanted arrival date',
            hourLabel: 'Wanted arrival hour:',
          },
          addPackage: {
            total: {
              title: 'Total:',
              piecesAbbreviation_zero: 'pc.',
              piecesAbbreviation_one: 'pc.',
              piecesAbbreviation_other: 'pcs.',
              cubeMeters: 'm³.',
            },
            sizesUnitTitle: 'Sizes',
            weightsUnitTitle: 'weights',
            descriptionTitle: 'Nature of the goods',
            contentsTitle: 'Contents',
            buttons: {
              addPackageTop: 'New package',
              selectPackage: 'Package {{idx}}',
            },
            package: {
              placeholders: {
                quantity:
                  '$t(pages.transportsWithLeftPanel.panels.packaging.quantity)',
                height: 'Height',
                length: 'Length',
                width: 'Width',
                weight: 'Weight',
                description: 'Add a description',
                content: 'Add a content',
              },
              switches: {
                dangerous: 'Dangereux',
                withCare: 'Fragile',
              },
            },
          },
          information: {
            transportTitle: 'Title of transport',
            transportTitlePlaceholder: 'Name your transport',
            tagTitle: 'Tag',
            tagPlaceholder: 'XXXXX',
            incoterm: 'Incoterm',
            select: 'Select',
            specialsRequestsTitle: 'Specials requests',
            textAreaPlaceholder: 'Write comment',
          },
          contacts: {
            titleDeparture: 'Departure contact',
            titleArrival: 'Arrival contact',
            form: {
              fields: {
                company: 'Company',
                lastName: 'Last name',
                firstName: 'First name',
                phone: 'Phone',
                email: 'Email address',
                comment: 'Comment',
              },
            },
          },
          summary: {
            title: 'Summary',
            departure: 'Departure',
            arrival: 'Arrival',
            dateFormat: 'MM/DD, hh:mm A',
            others: 'Others',
            tag: '$t(modals.createTransport.steps.information.tagTitle):',
            incoterm: '$t(modals.createTransport.steps.information.incoterm):',
            specialsRequests:
              '$t(modals.createTransport.steps.information.specialsRequestsTitle):',
            noSpecialRequest: 'No special request',
            buttons: {
              requestQuotation: 'Request a cotation',
              triggerTransport: 'Trigger transport',
            },
          },
        },
        starredAddresses: 'Your favorite addresses:',
        buttons: {
          next: 'Next',
          end: 'Finished',
        },
      },
      conversations: {
        title: 'Messages',
        hourFormat: 'hh:mm a',
        inputPlaceholder: 'Write your message...',
      },
      notifications: {
        title: 'Notifications',
        empty: 'Empty notifications',
        dateFormat: 'MM/DD/YYYY [at] hh:mm A',
        buttons: {
          readAll: 'Mark as read',
          showMore: 'Show more',
        },
        items: {
          types: {
            quotation: 'the cotation',
            transport: 'the transport',
          },
          transportEvent: 'A new event was added about {{itemType}}: {{name}}',
        },
      },
      requests: {
        header: {
          summary: 'Summary',
          quotation: 'Quotation',
          documents: 'Documents',
        },
      },
    },
    alerts: {
      waiting: 'Waiting...',
      successfullyLogged: 'You are now connected',
      validatedRequest: 'Request confirmed',
      userInformationUpdated: 'Saved information',
      userNotificationsPreferencesUpdated: 'Notifications preferences updated',
      newPhoneConfirmationCodeSent: 'A new code was sent',
      phoneConfirmed: 'Your phone number is confirmed',
      userDisabled: 'The user was disabled',
      userRoleUpdated: 'The user is now: {{role}}',
      passwordUpdated: 'Your password was successfully updated',
      emailSent: 'The email was send',
      pleaseSelectInList: 'Please select a suggested place',
      successfullyUploaded: 'Documents successfully uploaded',
    },
    errors: {
      form: {
        field_required: 'The {{path}} field is required',
        email_invalid: 'Invalid email address.',
        invalid_phone_number: 'Invalid phone number.',
        string_field_too_short: '{{path}} must be at least {{min}} characters.',
        password_rules:
          'Password must be at least 12 characters long, contain at least ' +
          'one lowercase letter, one uppercase letter, one digit, and one ' +
          'special character',
        passwords_must_match: 'Passwords must match.',
      },
      unknown: 'Unknown error.',
      'Internal Server Error': 'Internal server error.',
      'You are not allowed to access to this':
        'You are not allowed to do this action',
      'User not found': 'Unknown user.',
      'Wrong credentials': "The email and the password don't match.",
      'Invalid request parameters': 'Invalid parameter',
      'Unable to change the role of the only admin of this company':
        'Unable to change the role of the only admin of this company',
      'Code invalid': 'Invalid code',
      'Last code request less than 30 seconds, please wait':
        'Last code request less than 30 seconds, please wait',
      'Rate limit exceeded, retry in 1 minute': 'Too many requests.',
    },
  },
};
