import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { S360RoundLogo } from '../assets';

function LoadingScreen({ className }) {
  return (
    <div className={className}>
      <div className="center">
        <div className="logo">
          <S360RoundLogo className="logo-image" />
        </div>
        <div className="spinner-wrapper">
          <div className="spinner">
            <div className="inner">
              <div className="gap" />
              <div className="left">
                <div className="half-circle" />
              </div>
              <div className="right">
                <div className="half-circle" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

LoadingScreen.propTypes = {
  className: PropTypes.string.isRequired,
};

const StyledLoadingScreen = styled(LoadingScreen)`
  display: block;
  position: absolute;
  inset: 0;

  /* background-color: #121212; */
  background-color: white;
  z-index: 99999;
  pointer-events: none;

  .center {
    display: block;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .logo {
    width: 128px;
    margin: 0 auto;

    .logo-image {
      width: 128px;
      filter: drop-shadow(0 10px 6px rgb(0 0 0 / 20%));
    }
  }

  .spinner-wrapper {
    display: block;
    position: relative;
    width: 100%;
    min-height: 100px;
    height: 100px;
  }

  .spinner-wrapper .spinner {
    position: absolute;
    overflow: hidden;
    left: 50%;
    margin-left: -50px;
    animation: outer-rotate 2.9167s linear infinite;
  }

  .spinner-wrapper .spinner .inner {
    width: 100px;
    height: 100px;
    position: relative;
    animation: sporadic-rotate 5.25s cubic-bezier(0.35, 0, 0.25, 1) infinite;
  }

  .spinner-wrapper .spinner .inner .gap {
    position: absolute;
    inset: 0 49px;
    border-top: 10px solid;
    box-sizing: border-box;
  }

  .spinner-wrapper .spinner .inner .left,
  .spinner-wrapper .spinner .inner .right {
    position: absolute;
    top: 0;
    height: 100px;
    width: 50px;
    overflow: hidden;
  }

  .spinner-wrapper .spinner .inner .left .half-circle,
  .spinner-wrapper .spinner .inner .right .half-circle {
    position: absolute;
    top: 0;
    width: 100px;
    height: 100px;
    box-sizing: border-box;
    border: 10px solid #61dafb;
    border-bottom-color: transparent;
    border-radius: 50%;
  }

  .spinner-wrapper .spinner .inner .left {
    left: 0;
  }

  .spinner-wrapper .spinner .inner .left .half-circle {
    left: 0;
    border-right-color: transparent;
    animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
  }

  .spinner-wrapper .spinner .inner .right {
    right: 0;
  }

  .spinner-wrapper .spinner .inner .right .half-circle {
    right: 0;
    border-left-color: transparent;
    animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
  }

  @keyframes outer-rotate {
    0% {
      transform: rotate(0deg) scale(0.5);
    }

    100% {
      transform: rotate(360deg) scale(0.5);
    }
  }

  @keyframes left-wobble {
    0%,
    100% {
      transform: rotate(130deg);
    }

    50% {
      transform: rotate(-5deg);
    }
  }

  @keyframes right-wobble {
    0%,
    100% {
      transform: rotate(-130deg);
    }

    50% {
      transform: rotate(5deg);
    }
  }

  @keyframes sporadic-rotate {
    12.5% {
      transform: rotate(135deg);
    }

    25% {
      transform: rotate(270deg);
    }

    37.5% {
      transform: rotate(405deg);
    }

    50% {
      transform: rotate(540deg);
    }

    62.5% {
      transform: rotate(675deg);
    }

    75% {
      transform: rotate(810deg);
    }

    87.5% {
      transform: rotate(945deg);
    }

    100% {
      transform: rotate(1080deg);
    }
  }
`;

export default React.memo(StyledLoadingScreen);
