export { default as AirIcon } from './air.svg';
export { default as BookIcon } from './book.svg';
export { default as CalendarIcon } from './calendar.svg';
export { default as CheckIcon } from './check.svg';
export { default as CheckCircleIcon } from './check-circle.svg';
export { default as CircleAddIcon } from './circle-add.svg';
export { default as CircleCloseIcon } from './circle-close.svg';
export { default as CircleMinusIcon } from './circle-minus.svg';
export { default as CircleTopArrowIcon } from './circle-top-arrow.svg';
export { default as ClockIcon } from './clock.svg';
export { default as CloseIcon } from './close.svg';
export { default as Co2Icon } from './co2.svg';
export { default as Co2CloudIcon } from './co2-cloud.svg';
export { default as CompanyIcon } from './company.svg';
export { default as CurrentLocationIcon } from './current-location.svg';
export { default as DocumentIcon } from './document.svg';
export { default as DownArrowIcon } from './down-arrow.svg';
export { default as DownArrowFilledIcon } from './down-arrow-filled.svg';
export { default as DownloadCloudIcon } from './download-cloud.svg';
export { default as EditIcon } from './edit.svg';
export { default as EyeIcon } from './eye.svg';
export { default as EyeOffIcon } from './eye-off.svg';
export { default as FilesIcon } from './files.svg';
export { default as GlobeIcon } from './globe.svg';
export { default as InfoEmptyCircleIcon } from './info-empty-circle.svg';
export { default as InformationCircleIcon } from './information-circle.svg';
export { default as LittleDownArrowIcon } from './little-down-arrow.svg';
export { default as LogoutIcon } from './log-out.svg';
export { default as MapDestinationMarkerIcon } from './map-destination-marker.svg';
export { default as MapMarkerIcon } from './map-marker.svg';
export { default as MessageReadIcon } from './message-read.svg';
export { default as MessagesIcon } from './messages.svg';
export { default as NewMessageIcon } from './new-message.svg';
export { default as NotificationsIcon } from './notifications.svg';
export { default as PackageIcon } from './package.svg';
export { default as PdfIcon } from './pdf.svg';
export { default as PhoneBookIcon } from './phone-book.svg';
export { default as PlaneIcon } from './plane.svg';
export { default as PreviousIcon } from './previous.svg';
export { default as QuotationsIcon } from './quotations.svg';
export { default as ReloadIcon } from './reload.svg';
export { default as RightArrowIcon } from './right-arrow.svg';
export { default as SearchIcon } from './search.svg';
export { default as SendMessageIcon } from './send-message.svg';
export { default as SettingsIcon } from './settings.svg';
export { default as ShareIcon } from './share.svg';
export { default as SidebarIcon } from './sidebar.svg';
export { default as SmileysIcon } from './smileys.svg';
export { default as TruckIcon } from './truck.svg';
export { default as UpArrowIcon } from './up-arrow.svg';
export { default as UpDownArrowsIcon } from './up-down-arrows.svg';
export { default as VerticalDotsIcon } from './vertical-dots.svg';
export { default as WeightWithUnitIcon } from './weight-with-unit.svg';
export { default as XmlIcon } from './xml.svg';
