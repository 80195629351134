import Joi from 'joi';

const env = {
  NODE_ENV: process.env.NODE_ENV,
  DEBUG: process.env.DEBUG,
  API_URL: process.env.API_URL,
  GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY,
};

const envVarsSchema = Joi.object({
  NODE_ENV: Joi.string()
    .allow('development', 'production', 'test')
    .default('development'),
  DEBUG: Joi.boolean().default(false),
  API_URL: Joi.string().required(),
  GOOGLE_MAPS_API_KEY: Joi.string().required(),
});

const { error, value: envVars } = envVarsSchema.validate(env);
if (error) throw new Error(`Config validation error: ${error.message}`);

const config = {
  env: envVars.NODE_ENV,
  debug: !['production', 'test'].includes(envVars.NODE_ENV) || envVars.DEBUG,
  apiUrl: envVars.API_URL,
  googleMapsApiKey: envVars.GOOGLE_MAPS_API_KEY,
};

export default config;
